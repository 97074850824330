.footer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .footer-wrapper {
    display: flex;
    flex-direction: row;
  }

  .fotter-img {
    width: 375px;
    height: 156px;
    background-image: url('../../assets/images/mobile/footer.png');
    background-size: 375px 156px;
  }

  .fotter-line {
    flex: 1;
    min-height: 156px;
    background-repeat: repeat-x;
    background-size: 6.7px 156px;
  }

  .fotter-line-left {
    background-image: url('../../assets/images/mobile/footer-line-left.png');
  }

  .fotter-line-right {
    background-image: url('../../assets/images/mobile/footer-line-right.png');
  }
}

@media (min-width: 768px) {
  .footer-wrapper {
    display: flex;
    flex-direction: row;
  }

  .fotter-img {
    width: 740px;
    height: 287px;
    background-image: url('../../assets/images/footer.png');
    background-size: 746px 287px;
  }

  .fotter-line {
    flex: 1;
    min-height: 287px;
    background-repeat: repeat-x;
    background-size: 4px 287px;
  }

  .fotter-line-left {
    background-image: url('../../assets/images/footer-line-left.png');
  }

  .fotter-line-right {
    background-image: url('../../assets/images/footer-line-right.png');
  }
}
