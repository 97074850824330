.location-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.location-title-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.location-label {
  font-family: SpaceGrotesk, SpaceGrotesk-SemiBold;
  font-weight: SemiBold;
  text-align: left;
  color: #0d1921;
}

.location-info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: SpaceGrotesk, SpaceGrotesk-Regular;
  color: #0d1921;
}

@media only screen and (max-width: 768px) {
  .location-wrapper {
    padding-top: 45px;
  }

  .location-icon {
    width: 18px;
    height: 18px;
  }

  .location-label {
    font-size: 16px;
    line-height: 21px;
    padding-left: 5px;
  }

  .location-info-wrapper {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding-top: 14px;
    padding-bottom: 50px;
  }

  .location-info{
    max-width: 280px;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .location-wrapper {
    padding-top: 120px;
  }

  .location-icon {
    width: 28px;
    height: 28px;
  }

  .location-label {
    font-size: 24px;
    line-height: 31px;
    padding-left: 10px;
  }

  .location-info-wrapper {
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    padding-top: 24px;
    padding-bottom: 40px;
  }

  .location-info{
    max-width: 420px;
    text-align: center;
  }
}
