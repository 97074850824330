.logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .logo-wrapper {
    padding-top: 23px;
  }

  .logo {
    width: 121px;
    height: 60px;
  }
}

@media (min-width: 768px) {
  .logo-wrapper {
    padding-top: 68px;
  }

  .logo {
    width: 194px;
    height: 96px;
  }
}
