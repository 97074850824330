@font-face {
  font-family: SpaceGrotesk-Bold;
  src: url('./assets/fonts/SpaceGrotesk-Bold.ttf');
}

@font-face {
  font-family: SpaceGrotesk-Light;
  src: url('./assets/fonts/SpaceGrotesk-Light.ttf');
}

@font-face {
  font-family: SpaceGrotesk-Medium;
  src: url('./assets/fonts/SpaceGrotesk-Medium.ttf');
}

@font-face {
  font-family: SpaceGrotesk-Regular;
  src: url('./assets/fonts/SpaceGrotesk-Regular.ttf');
}

@font-face {
  font-family: SpaceGrotesk-SemiBold;
  src: url('./assets/fonts/SpaceGrotesk-SemiBold.ttf');
}

body {
  margin: 0;
  font-family: SpaceGrotesk, SpaceGrotesk-Regular, SpaceGrotesk-Bold,
    SpaceGrotesk-SemiBold, SpaceGrotesk-Light, SpaceGrotesk-Medium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
