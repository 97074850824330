.expired-warpper {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
}

.expired-main-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.expired-info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.expired-label {
  font-family: SpaceGrotesk, SpaceGrotesk-Regular;
  color: #0d1921;
}

@media only screen and (max-width: 768px) {
  .expired-main-wrapper {
    min-height: 300px;
    justify-content: center;
  }

  .expired-icon {
    width: 40px;
    height: 40px;
    padding-bottom: 16px;
  }

  .expired-label {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}

@media (min-width: 768px) {
  .expired-main-wrapper {
    min-height: 500px;
  }

  .expired-icon {
    width: 80px;
    height: 80px;
    padding-bottom: 20px;
  }

  .expired-label {
    font-size: 25px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 1px;
  }
}
